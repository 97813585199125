import { useState, useEffect, useRef } from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ReactPlayer from 'react-player'
import { useHover } from "@uidotdev/usehooks";
import axios from 'axios'

import Nav from 'react-bootstrap/Nav';
import { Navbar, Container } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Facebook, Instagram,TelephoneFill,Youtube,Linkedin,Tiktok, Whatsapp, Envelope } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Marquee from "react-fast-marquee";
import Accordion from 'react-bootstrap/Accordion';
import { isMobile } from 'react-device-detect';
import PhotoAlbum from "react-photo-album";

import logo from './img/logo.png';
import logoWhats from './img/afirmativo-whats.jpg';
import video from './img/video.png'  
import bgus from './img/bgnosotros.png'  
import tequila from './img/tequila.png'
import mebs from './img/MEBS.png'

import boss from './img/clientes/boss.png'
import cristal from './img/clientes/cristal.png'
import cuadra from './img/clientes/cuadra.png'
import dyson from './img/clientes/dyson.png'
import genox from './img/clientes/genox.png'
import gigante from './img/clientes/gigante.png'
import hp from './img/clientes/hp.png'
import hut from './img/clientes/hut.png'
import lumbre from './img/clientes/lumbre.png'
import materias from './img/clientes/materias.png'
import monkey from './img/clientes/monkey.png'
import ramon from './img/clientes/ramon.png'
import recorcholis from './img/clientes/recorcholis.png'
import ventura from './img/clientes/ventura.png'
import waldos from './img/clientes/waldos.png'
import walmart from './img/clientes/walmart.png'

import contraluz1 from './img/contraluz/contraluz1.webp'
import contraluz2 from './img/contraluz/contraluz2.webp'
import contraluz3 from './img/contraluz/contraluz3.webp'
import contraluz4 from './img/contraluz/contraluz4.webp'

import free1 from './img/free/free1.webp'
import free2 from './img/free/free2.webp'
import free3 from './img/free/free3.webp'
import free4 from './img/free/free4.webp'
//import free5 from './img/free/free5.png'

import cuadra1 from './img/cuadra/cuadra1.webp'
import cuadra2 from './img/cuadra/cuadra2.webp'
import cuadra3 from './img/cuadra/cuadra3.webp'
import cuadra4 from './img/cuadra/cuadra4.webp'
//import cuadra5 from './img/cuadra/cuadra5.png'

import six1 from './img/six/six1.webp'
import six2 from './img/six/six2.webp'
import six3 from './img/six/six3.webp'
import six4 from './img/six/six4.webp'
//import six5 from './img/six/six5.png'

import malo1 from './img/malo/malo1.webp'
import malo2 from './img/malo/malo2.webp'
import malo3 from './img/malo/malo3.webp'
import malo4 from './img/malo/malo4.webp'
//import hp5 from './img/hp/hp5.png'

import pepsi1 from './img/pepsi/pepsi1.webp'
import pepsi2 from './img/pepsi/pepsi2.webp'
import pepsi3 from './img/pepsi/pepsi3.webp'
import pepsi4 from './img/pepsi/pepsi4.webp'

import walmart1 from './img/walmart/walmart1.webp'
import walmart2 from './img/walmart/walmart2.webp'
import walmart3 from './img/walmart/walmart3.webp'
import walmart4 from './img/walmart/walmart4.webp'

import hugo1 from './img/hugoboss/hugo1.webp'
import hugo2 from './img/hugoboss/hugo2.webp'
import hugo3 from './img/hugoboss/hugo3.webp'
import hugo4 from './img/hugoboss/hugo4.webp'

import mas from './img/paloma.png'
import circulo from './img/circulo.png'
import location from './img/location.png'
import paloma from './img/paloma.png'

import insta from './img/insta.png'
import face from './img/face.png'
import linked from './img/in.png'
import tik from './img/tik.png'

import service1 from './img/service1.png'
import service2 from './img/service2.png'
import service3 from './img/service3.png'
import service4 from './img/service4.png'
import service5 from './img/service5.png'
import service6 from './img/service6.png'
import service7 from './img/service7.png'

import contactoImg from './img/contact.gif'

import './App.css';
import 'animate.css';

const host = 'https://afirmativo.mx/'
//const host = 'http://localhost:3000/'
//const host = 'http://qa.mgpublicidad.mx/'

const photos = [
  {
    src: host + 'projects/gran_malo.png',
    width: 906,
    height: 1198,
    title: 'GRAN MALO',
    desc: 'El licor de tequila Gran Malo es reconocido por su prestigioso evento anual de halloween. Para su pasada edición realizamos un diseño que representó con fidelidad todo el espíritu de la temporada más aterradora del año. Fusionamos estética y funcionalidad con resultados incomparables, nuestros exhibidores están diseñados para resaltar tus productos y atraer a tus clientes más macabros.'
     
  },
  {
    src: host + 'projects/adidas.png',
      width: 838,
      height: 795,
      title: 'ADIDAS',
      desc: 'La reconocida compañía multinacional alemana, reconocida por su calzado deportivo y ropa de moda. Lanzó una campaña publicitaria que requería llegar a mercados específicos con un mensaje completamente visual. Para tal efecto, utilizamos la mejor tecnología en pantallas LED. Adidas se volvió el protagonista de cada camino. ¡Viste de luz tu marca y resalta cada día!'
     
  },
  {
    src: host + 'projects/terraza.png',
      width: 835,
      height: 1184,
      title: 'TERRAZA PIRULES',
      desc: '¡Crea un ambiente cautivador! Para espacios de alto nivel y sofisticación como Terraza Pirules. Se produjo una atmósfera que expresó tanto elegancia como refinamiento. Combinamos cada elemento en una fusión de formas y color. Convertimos su espacio en la experiencia de altura que necesitaban.'

  },
  {
    src: host + 'projects/michael.png',
      width: 870,
      height: 683,
      title: 'MICHEL DOMMIT ',
      desc: 'La empresa mexicana líder en la industria de calzado y productos de piel, necesitaba generar en sus clientes una experiencia de compra satisfactoria y placentera. Para lograrlo, producimos un ambiente de lujo y sofisticación, con especial atención en cada detalle cultivamos fidelidad hacia la marca e incentivamos las ventas. ¡Porque la primera impresión sí es la que cuenta!'
  },
  {
    src: host + 'projects/berrendo.png',
    width: 875,
    height: 594,
    title: 'BERRENDO',
    desc: 'Berrendo es una empresa con más de 80 años de experiencia, especializada en calzado industrial. Presentó su más reciente colección, para la ocasión capturamos toda su esencia única y vanguardista en un stand con mucha presencia. Causar una impresión duradera, utilizar los mejores materiales y evolucionar en cada diseño… ¡Es nuestra pasión! '
  },
  {
    src: host + 'projects/mezcal.png',
      width: 864,
      height: 873,
      title: 'MEZCAL SANTO GUSANO',
      desc: 'Durante la presentación del mezcal Santo Gusano, desarrollamos una impresión en 3D que reflejó toda su personalidad, al ser un producto 100% artesanal proveniente de Oaxaca, requería un diseño único y con estilo. Deja que nuestra creatividad y experiencia guíen el camino hacia el éxito de tu proyecto.'
  },
  {
    src: host + 'projects/upfront.png',
    width: 870,
    height: 921,
    title: 'UPFRONT 2024',
    desc: 'Upfront 2024 en asociación con Cinépolis y +QueCine, presentaron diferentes películas mexicanas que se estrenarán a lo largo del año. Sumergimos a su audiencia en un evento verdaderamente auténtico. Conquistamos la pupila del consumidor, al mismo tiempo que innovamos con gran destreza y logística. ¡Creamos toda una vivencia sensorial!'
  }
];

function App() {
  const [goingUp, setGoingUp] = useState(false)
  const prevScrollY = useRef(0)

  const [isLoading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const [showMessage, setShowMessage] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const refHome = useRef()
  const refUs = useRef()
  const refBook = useRef()
  const refServices = useRef()
  const refProjects = useRef()
  const refContact = useRef()

  const [refMarketing, hoveringMarketing] = useHover();
  const [refExhibidores, hoveringExhibidores] = useHover();
  const [refMoldes, hoveringMoldes] = useHover();
  const [refVolumen, hoveringVolumen] = useHover();
  const [refMobiliario, hoveringMobiliario] = useHover();
  const [refImpresion, hoveringImpresion] = useHover();
  const [refPublicidad, hoveringPublicidad] = useHover();
  const [refEventos, hoveringEventos] = useHover();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if( window.location.pathname == '/gracias' ) {
      setLoading(false)
      setTitle("Mensaje enviado")
      setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
      handleShow()
    }
  })

  useEffect(() => {
    var flag = false

    if(hoveringExhibidores || hoveringMarketing || hoveringMoldes || hoveringVolumen || hoveringMobiliario || hoveringImpresion || hoveringPublicidad) flag = true

    if( flag )
      clearHover()

    if (hoveringExhibidores) {
      setServiceSelected('hoveringExhibidores')
      document.getElementById("pop").classList.add('l-purple')
    } else if(hoveringMarketing){
      setServiceSelected('hoveringMarketing')
      document.getElementById("retail").classList.add('l-green')
    } else if(hoveringMoldes) {
      setServiceSelected('hoveringMoldes')
      document.getElementById("stands").classList.add('l-yellow')
    } else if(hoveringVolumen) {
      setServiceSelected('hoveringVolumen')
      document.getElementById("eventos").classList.add('l-red')
    } else if(hoveringMobiliario) {
      setServiceSelected('hoveringMobiliario')
      document.getElementById("espacios").classList.add('l-blue')
    } else if(hoveringImpresion) {
      setServiceSelected('hoveringImpresion')
      document.getElementById("proyectos").classList.add('l-orange')
    } else if(hoveringPublicidad) {
      setServiceSelected('hoveringPublicidad')
      document.getElementById("tecno").classList.add('l-lemon')
    }
    
    /*else if(hoveringEventos) {
      setServiceSelected('hoveringEventos')
      document.getElementById("pop").classList.add('l-purple')
    }*/
  }, [hoveringMarketing, hoveringExhibidores, hoveringMoldes, hoveringVolumen, hoveringMobiliario, hoveringImpresion, hoveringPublicidad, hoveringEventos]);

  const clearHover = () => {

    document.getElementById("pop").classList.remove('l-purple')
    document.getElementById("retail").classList.remove('l-green')
    document.getElementById("stands").classList.remove('l-yellow')
    document.getElementById("eventos").classList.remove('l-red')
    document.getElementById("espacios").classList.remove('l-blue')
    document.getElementById("proyectos").classList.remove('l-orange')
    document.getElementById("tecno").classList.remove('l-lemon')
    
  }

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LdCWnsnAAAAAPzMX6aHkNNvs4a47SN1jI6ptYbM`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      var prevScrollYTemp = prevScrollY.current;
      
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10) {
        document.getElementById("mg-navbar").className = "bg-header navbar navbar-expand-lg navbar-dark fixed-top";
      } else {
        document.getElementById("mg-navbar").className = "bg-header-start navbar navbar-expand-lg navbar-dark fixed-top";
      }

      /*if (prevScrollYTemp < currentScrollY) {
        if ( !document.getElementById("mg-navbar").classList.contains('navbar-hidden') ) {
          document.getElementById("mg-navbar").classList.remove("navbar-show");
          document.getElementById("mg-navbar").classList.add("navbar-hidden");
        }
      } else if (prevScrollYTemp > currentScrollY) {
        if ( !document.getElementById("mg-navbar").classList.contains('navbar-show') ) {
          document.getElementById("mg-navbar").classList.add("navbar-show");
          document.getElementById("mg-navbar").classList.remove("navbar-hidden");
        }
      }*/
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const handleClick = (eventKey) => {

    var element = eval(eventKey).current;
    var headerOffset = 90;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });

    /*eval(eventKey).current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })*/

  };
  const [ serviceIndex, setServiceIndex ] = useState(-1)
  const [ serviceSelected, setServiceSelected ] = useState("hoveringExhibidores")
  const handleOnClick = (e) => {
    console.log(e);
    //setServiceIndex(e);
  }

  const handleShow = () => setShowMessage(true);
  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LdCWnsnAAAAAPzMX6aHkNNvs4a47SN1jI6ptYbM", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://inmobiplace.com/api/contactAfirmativo/", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setTitle("Error")
              setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
              handleShow()
            } else {
              window.location.href = "/gracias"
              /*setLoading(false)
              setTitle("Mensaje enviado")
              setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
              handleShow()

              setName("");
              setPhone("");
              setEmail("");
              setComments("");*/
            }

          }).catch(function(err) {
            setLoading(false)
            setTitle("Error")
            setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
            handleShow()
          });
      //} else 
        //alert("Por favor, verifica que no eres un robot")
      });
    });
  }

  const closeModalSent = () => {
    if( title == 'Error') setShowMessage(false)
    else window.location.href = "/"
  }

  return (
    <>

      <Modal show={showMessage} onHide={ () => closeModalSent() }>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={ () => closeModalSent() }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="App">
        <FloatingWhatsApp 
          phoneNumber="5215567020600"
          accountName="Afirmativo"  
          className="wa-style"
          statusMessage="En linea"
          avatar={ logoWhats }
          chatMessage="Hola, ¡Bienvenido a Afirmativo!. ¿Cómo podemos ayudarte?"
          allowClickAway
          notification={false}
        />
          <header className='header'>
        <Container >
      <Navbar id="mg-navbar" collapseOnSelect expand="lg" className={ "bg-header-start" } variant="dark" fixed="top">
        <Container>
              <Navbar.Brand href="/">
                <img src={logo} height="60" className="d-inline-block" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                
                <Nav className="me-auto"></Nav>
                
                <Nav onSelect={ handleClick } className='align-items-center'>
                  <Nav.Link eventKey="refUs" className="nav-link-comp font-heder">
                    Nosotros
                  </Nav.Link>
                  {/* <Nav.Link eventKey="refBook" className="nav-link font-heder">
                    Book  
                  </Nav.Link>*/}
                  <Nav.Link eventKey="refProjects" className="nav-link font-heder">
                    Proyectos
                  </Nav.Link>
                  <Nav.Link eventKey="refServices" className="nav-link font-heder">
                    Servicios
                  </Nav.Link>
                  <Nav.Link eventKey="refContact" className="nav-link font-heder">
                    Contacto
                  </Nav.Link>
                  
                </Nav>

                <Nav className="me-auto"></Nav>

                <Nav className='align-items-center'>

                  <Nav.Link>
                      <div style={{ width: '40px' }} ></div>
                  </Nav.Link>

                </Nav>

              </Navbar.Collapse>
              
            </Container>
          </Navbar>
        </Container>
        </header>
        
        <div ref={refHome}>
          <ReactPlayer className="player-v" url='af.mp4' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ true } />  
          {/* <img src={video}/> */}
        </div>
        <div className='banner-us' ref={refUs}>
          <Container>
            <Row className='align-items-center'>
              <Col md={ 6 } xs={ 12 } className='titles-us'><div className='stroke-title'>¿Quiénes</div><div className='secundary-title'>somos?</div></Col>
              <Col md={ 5 }xs={ 12 } className='description-us line-us'>
                  <div style={{ color: "#fff"}}>Somos una agencia integral especializada en <b>diseño, producción, implementación y mantenimiento,</b> que ofrece soluciones personalizadas y experiencias únicas. Nuestra combinación de creatividad, experiencia y enfoque individual en cada proyecto asegura un impacto duradero para tu marca.</div>
              </Col>
            </Row>
          </Container>
        </div>
        <div ref={ refProjects } className='section section-round' style={{background: '#000' }}>
          <Container>
              <div className='home-title-black'><span className="line"></span></div>
              <br/>
              <div className='home-white-proyect'> PROYECTOS </div>
              <PhotoAlbum className='bg-album' layout="columns" photos={photos}  columns={ isMobile ? 1 : 2 }
                  renderPhoto={({ photo, imageProps: { src, alt, style, ...restImageProps } }) => (
                    <div className='img-container' style={{ position: 'relative' }}>
                      <img src={src} alt={alt} style={{ ...style }} {...restImageProps} />
                      <div className="centered">
                        <div className='image-title'>{ photo.title }</div>
                        <hr className='img-line'/>
                        <div className='image-desc'>{ photo.desc }</div>
                      </div>
                    </div>
                  )}
                  /*renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                      <div style={{ position: "relative", ...wrapperStyle }}>
                          { renderDefaultPhoto({ wrapped: false }) }
                          <div className='photo-desc'>
                              <div style={{ textTransform: "uppercase", textAlign: 'right' }} className="title-item">
                                  {photo.title}
                              </div>  
                              <div className='subtitle-item'>
                                  {photo.subtitle}
                              </div>
                          </div> 
                      </div>
                  )}*/
              />
          </Container>
        </div>
        {/*<div className='section' ref={ refProjects } style={{background: '#000' }}>
          <br/>
          <Container>
            <div className='home-white-proyect'>PROYECTOS</div>
          </Container>
          
          <Carousel>
            <Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-halo'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ contraluz1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right text-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>CONTRALUZ </div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>El cantante colombiano Maluma es uno de los famosos que se ha dejado seducir por los encantos de las bebidas mexicanas, por lo que ha incursionado en la comercialización de su propia etiqueta de mezcal: Contraluz. Para posicionar el destilado, la exhibición es prioridad, con diseños vanguardistas, que represente lujo y una estructura que permita el fácil acceso al producto, nuestra propuesta pretende que el cliente tenga una mejor experiencia y retengamos su atención.</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={ 4 } xs={ 6 }>
                          <img src={contraluz3} className='imgs-containers photos-stants-right' />
                        </Col>   
                        <Col md={ 4 } xs={ 6 }>
                          <img src={contraluz4} className='imgs-containers photos-stants-right' />
                        </Col>  
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={contraluz2} className='imgs-containers photos-stants-right' />
                        </Col> 
                      </Row>
                    </Col>
                  {<Col sm={4} md={4} xs={12}>
                    <Row className="align-items-center">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ contraluz2 } className='img-gallery' /> 
                      </Col>
                    </Row>
                  </Col>}
                </Row>
              </Container>
              <br/>
            </Carousel.Item>
            
            <Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-free'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ free1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right text-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>FREE FIRE </div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>El juego móvil más descargado a nivel global en 2019 requería una campaña igual de impactante que el videojuego, que permitiera a los usuarios sumergirse en su universo. Así, creamos un escenario digno de las mejores batallas, con mobiliario innovador y funcional donde combinamos todos los elementos esenciales de la marca.</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ free2 } className='imgs-containers photos-stants-right' />
                        </Col>   
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ free3 } className='imgs-containers photos-stants-right' />
                        </Col>  
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={ free4 } className='imgs-containers photos-stants-right' />
                        </Col> 
                      </Row>
                    </Col>
                  {<Col sm={4} md={4} xs={12}>
                    <Row className="align-items-center">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ free4 } className='img-gallery' /> 
                      </Col>
                    </Row>
                    <Row className="align-items-center container-row">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ free5 } className='img-gallery' /> 
                      </Col>
                    </Row>
                  </Col>}
                </Row>
              </Container>
              <br/>
            </Carousel.Item>

            <Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-cuadra'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ cuadra1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right text-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>CUADRA</div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>Cuadra, empresa líder en la fabricación de productos de piel, hechos a mano con los más altos estándares de diseño, calidad y vanguardia, se mantienen en constante crecimiento, y para ello apuesta a la exhibición de su marca a través de diferentes canales, como los stands, espacios donde puede comunicar sus valores e identidad corporativa. Una buena estructura y los elementos de diseño adecuados impulsan su presencia de marca y genera mayores ventas. </span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ cuadra2 } className='imgs-containers photos-stants-right' />
                        </Col>   
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ cuadra3 } className='imgs-containers photos-stants-right' />
                        </Col>  
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={ cuadra4 } className='imgs-containers photos-stants-right' />
                        </Col>  
                      </Row>
                    </Col>
                  {<Col sm={4} md={4} xs={12}>
                    <Row className="align-items-center">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ cuadra4 } className='img-gallery' /> 
                      </Col>
                    </Row>
                    <Row className="align-items-center container-row">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ cuadra5 } className='img-gallery' /> 
                      </Col>
                    </Row>
                  </Col>}
                </Row>
              </Container>
              <br/>
            </Carousel.Item>        

            <Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-six'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ six1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right texts-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>SIX FLAGS</div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>Generamos mensajes oportunos y de gran efectividad a través de publicidad exterior para Six Flags, el parque temático más visitado de Latinoamérica. Combinamos estretegias publicitarias con multimedia para un mensaje dinámico, visualmente atractivo y con altas posibilidades de éxito.</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ six2 } className='imgs-containers photos-stants-right' />
                        </Col>   
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ six3 } className='imgs-containers photos-stants-right' />
                        </Col> 
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={ six4 } className='imgs-containers photos-stants-right' />
                        </Col>  
                      </Row>
                    </Col>
                  {<Col sm={4} md={4} xs={12}>
                    <Row className="align-items-center">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ six4 } className='img-gallery' /> 
                      </Col>
                    </Row>
                    <Row className="align-items-center container-row">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ six5 } className='img-gallery' /> 
                      </Col>
                    </Row>
                  </Col>} 
                </Row>
              </Container>
              <br/>
            </Carousel.Item>

            <Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-hugo'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ hugo1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right texts-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>HUGO BOSS</div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>Hugo Boss, la firma alemana de moda, se ha convertido en uno de los principales estandartes de estilo europeo. Para su nueva colaboración con la NFL, elaboramos un stand que combina la pasión por la moda y el futbol americano. Nuestra propuesta apuesta a la funcionalidad sin dejar a un lado la estética, con un diseño atractivo que se destaca y ofrece una oportunidad de interacción cercana con sus clientes.</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ hugo2 } className='imgs-containers photos-stants-right' />
                        </Col>
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ hugo3 } className='imgs-containers photos-stants-right' />
                        </Col> 
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={ hugo4 } className='imgs-containers photos-stants-right' />
                        </Col>  
                      </Row>
                    </Col>
                  {<Col sm={4} md={4} xs={12}>
                    <Row className="align-items-center">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ six4 } className='img-gallery' /> 
                      </Col>
                    </Row>
                    <Row className="align-items-center container-row">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ six5 } className='img-gallery' /> 
                      </Col>
                    </Row>
                  </Col>} 
                </Row>
              </Container>
              <br/>
            </Carousel.Item>

            {<Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-hp'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ malo1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right text-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>GRAN MALO</div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>El estilo sencillo y sincero de uno de los influencers más destacados de los últimos años, Luisito Comunica, queda impreso en un exhibidor fabricado para la línea de destilados Gran Malo, el licor de tequila infusionado con sabores clásicos de las mesas mexicanas. La finalidad, impulsar un producto de temporada, dar visualización a la marca captando la atención del consumidor, a través de una propuesta joven, sustentable y atractiva.</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ malo2 } className='imgs-containers photos-stants-right' />
                        </Col>   
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ malo3 } className='imgs-containers photos-stants-right' />
                        </Col> 
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={ malo4 } className='imgs-containers photos-stants-right' />
                        </Col>  
                      </Row>
                    </Col>
                </Row>
              </Container>
              <br/>
            </Carousel.Item>}

            <Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-pepsi'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ pepsi1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right text-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>PEPSI</div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>Reflejamos la filosofía de la marca aportando valores estéticos, visuales y funcionales a través de exhibidores. Para Pepsi, líder global en bebidas, realizamos exhibidores, que tienen la función de hacer publicidad, optimizar espacios e incrementar su rentabilidad. Fabricamos en madera, mdf, aglomerado, acrílico, cartón, etc.</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ pepsi2 } className='imgs-containers photos-stants-right' />
                        </Col>   
                        <Col md={ 4 } xs={ 6 }>
                          <img src={ pepsi3 } className='imgs-containers photos-stants-right' />
                        </Col>  
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={ pepsi4 } className='imgs-containers photos-stants-right' />
                        </Col>  
                      </Row>
                    </Col>
                  {<Col sm={4} md={4} xs={12}>
                    <Row className="align-items-center">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ pepsi4 } className='img-gallery' /> 
                      </Col>
                    </Row>
                  </Col>} 
                </Row>
              </Container>
              <br/>
            </Carousel.Item>

            <Carousel.Item className='back-ground-services'>
              <Container>
                <Row className='carousel-card-padding'>
                    <Col sm={4} md={12} xs={12}>
                      <Row className="align-items-center containers-photos">
                        <Container className='containers-photos bg-pepsi'>
                          <Row className='align-items-center'>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-left '>
                                <img src={ walmart1 } className='imgs-containers bg-halod  ' />
                            </Col>
                            <Col md={ 6 } xs={ 12 } className='containers-photos-right text-desc-book bg-halod '>
                              <div className='text-title-book bg-halod'>WALMART</div>
                              <div>
                                <br />
                                <span className='text-desc-book bg-halod'>Con más de 30 años en el mercado mexicano, Walmart se mantiene como una de las cadenas minoristas más importantes en nuestro país, debido a su constante innovación y diversificación. Para Walmart, los stands se han convertido en una de sus mejores herramientas para facilitar la exposición de sus marcas y generar oportunidades de venta.</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Row>
                      <Row className="align-items-center container-row">
                        <Col md={4} xs={6}>
                          <img src={ walmart2 } className='imgs-containers photos-stants-right' />
                        </Col>   
                        <Col md={4} xs={6}>
                          <img src={ walmart3 } className='imgs-containers photos-stants-right' />
                        </Col>  
                        <Col md={ 4 } xs={ 12 } className='last-col'>
                          <img src={ walmart4 } className='imgs-containers photos-stants-right' />
                        </Col>
                      </Row>
                    </Col>
                    {<Col sm={4} md={4} xs={12}>
                    <Row className="align-items-center">
                      <Col style={{ paddingTop: '15px' }}>
                        <img src={ walmart4 } className='img-gallery' /> 
                      </Col>
                    </Row>
                  </Col>}   
                </Row>
              </Container>
              <br/>
            </Carousel.Item>
            
          </Carousel>
        </div>*/}

        <div ref={ refServices } className='section'>
        {isMobile ? (
        <Container>
          <Row>
            <div className='home-title-ment'>SERVICIOS <span className="line-ment" style={{ borderColor: '#71c2b3' }}></span>
            </div>
          </Row>
          <br/>
          <Row >
            <Accordion className="accordion-container">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Container>
                    <Row>
                      <Col xs={ 2 }>
                        <div> <img src={ paloma } className='home-services-icon'/> </div>
                      </Col>
                      <Col xs={ 10 }>
                        <div className='home-services-title'>POP</div>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='home-subtitle-white'>Display multimaterial<br /></div><br />

                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Piso</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Side kick</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Pallet</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Counter</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>PDQ</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Cabeceras</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Glorificadores </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Góndolas </div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                  <br/>
                  <div className='home-subtitle-white'>Impresión<br /></div><br />

                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Offset </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Rígidos </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Pallet</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Flexibles <span style={{ fontSize: '10px', lineHeight: 'normal' }}>(Cenefas, copetes, banderolas, lonas, stopper, predicadores, flyers, etiquetas y tarjetas de presentación)</span></div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <Container>
                    <Row>
                      <Col xs={ 2 }>
                        <div> <img src={ paloma } className='home-services-icon'/> </div>
                      </Col>
                      <Col xs={ 10 }>
                        <div className='home-services-title'>Retail</div>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body>
                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Mesas de experiencia </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Corners </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Escaparates</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Vitrinas</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Merchandising</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Señaléticas</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Tótems</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Letreros </div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <Container>
                    <Row>
                      <Col xs={ 2 }>
                        <div> <img src={ paloma } className='home-services-icon'/> </div>
                      </Col>
                      <Col xs={ 10 }>
                        <div className='home-services-title'>Stands publicitarios</div>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body>
                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Islas en centros comerciales</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Photo opportunity </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Demo stand </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Ferias y exposiciones (Octanorm y Custorm) </div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <Container>
                    <Row>
                      <Col xs={ 2 }>
                        <div> <img src={ paloma } className='home-services-icon'/> </div>
                      </Col>
                      <Col xs={ 10 }>
                        <div className='home-services-title'>Eventos y convenciones</div>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body>
                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Festivales </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Eventos de marca  </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Convenciones </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Corporativo </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Seminarios  </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Escenarios  </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Conferencias  </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Audio  </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Video  </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Pantallas led  </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Alfombra roja y pasarelas  </div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <Container>
                    <Row>
                      <Col xs={ 2 }>
                        <div> <img src={ paloma } className='home-services-icon'/> </div>
                      </Col>
                      <Col xs={ 10 }>
                        <div className='home-services-title'>Espacios fijos e interiorismo</div>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='home-subtitle-white'>Mobiliario<br /></div>

                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Corporativo </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Hotelería</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Restaurantes </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Residencial</div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <Container>
                    <Row>
                      <Col xs={ 2 }>
                        <div> <img src={ paloma } className='home-services-icon'/> </div>
                      </Col>
                      <Col xs={ 10 }>
                        <div className='home-services-title'>Proyectos especiales</div>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body>

                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Volumétricos</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Impresión 3D</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Anuncios luminosos </div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Logística</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Staff</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Mantenimiento</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Branding</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Re-branding</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Packaging</div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <Container>
                    <Row>
                      <Col xs={ 2 }>
                        <div> <img src={ paloma } className='home-services-icon'/> </div>
                      </Col>
                      <Col xs={ 10 }>
                        <div className='home-services-title'>Tecnología</div>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Header>
                <Accordion.Body>

                  <Container className='home-desc-section'>
                      <Row>
                        <Col md={ 12 }>
                          <Container>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Renta de pantallas</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Venta de pantallas</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Contenido</div></Col>
                            </Row>
                            <Row>
                                <Col md={ 'auto' } xs={ 2 }><img src={circulo} className='img-circulo'/> </Col>
                                <Col xs={ 10 } className='padd-left'><div className='margin-service service-tab'>Instalación</div></Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>


            </Accordion>
          </Row>
        </Container>
    ) : (
    <div className='home-pos' style={{ paddingBottom: '0' }}>
    <Container >
        <Row>
          <div className='home-title-ment'>Servicios <span className="line-ment" style={{ borderColor: '#71c2b3' }}></span></div>
        </Row>
        <br/>
        <Row >
          <Col md={7} xs={ 7}>                
            { serviceSelected == 'hoveringExhibidores' &&
              <div id="service_0" className={ "animate__animated animate__fadeInUp send-front-90 animate__fast" }>
                <Row>
                  <Col md={1} xs={ 1}>
                    <div><img src={mas} className='img-paloma' /></div>
                  </Col>
                  <Col md={1} xs={ 1}>
                    <img src={service1} className='img-paloma'/>
                  </Col>
                  <Col md={10} xs={10}>
                    <div className='home-subtitle-white'>Display multimaterial<br /></div><br />

                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 'auto' }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Piso</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Side kick</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Pallet</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Counter</div></Col>
                              </Row>
                            </Container>
                          </Col>
                          <Col>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>PDQ</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Cabeceras</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Glorificadores </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Góndolas </div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                    </Container>
                    <br/>
                    <div className='home-subtitle-white'>Impresión<br /></div><br />

                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 12 }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Offset</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Rígidos</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Flexibles <span style={{ fontSize: '10px', lineHeight: 'normal' }}>(Cenefas, copetes, banderolas, lonas, stopper, predicadores, flyers, etiquetas y tarjetas de presentación)</span></div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>                                  
                    </Container>
                  </Col>
                </Row>       
              </div>
            }

            { serviceSelected == 'hoveringMarketing' &&
              <div id="service_1" className={ "animate__animated animate__fadeInUp send-front-90 animate__fast" }>
                <Row>
                  <Col md={1} xs={ 1}>
                    <div><img src={mas} className='img-paloma' /></div>
                  </Col>
                  <Col md={1} xs={ 1}>
                    <img src={service2} className='img-paloma'/>
                  </Col>
                  <Col md={10} xs={10}>
                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 12 }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Mesas de experiencia</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Corners</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Escaparates</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Vitrinas</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Merchandising</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Señaléticas</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Tótems</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Letreros </div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                    </Container>
                  </Col>
                </Row>       
              </div>
            }

            { serviceSelected == 'hoveringMoldes' &&
              <div id="service_2" className={ "animate__animated animate__fadeInUp send-front-90 animate__fast" }>
                <Row>
                  <Col md={1} xs={ 1}>
                    <div><img src={mas} className='img-paloma' /></div>
                  </Col>
                  <Col md={1} xs={ 1}>
                    <img src={service3} className='img-paloma'/>
                  </Col>
                  <Col md={10} xs={10}>
                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 12 }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Islas en centros comerciales</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Photo opportunity </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Demo stand </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Ferias y exposiciones (Octanorm y Custorm) </div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                    </Container>
                  </Col>
                </Row>       
              </div>
            }

            { serviceSelected == 'hoveringVolumen' &&
              <div id="service_2" className={ "animate__animated animate__fadeInUp send-front-90 animate__fast" }>
                <Row>
                  <Col md={1} xs={ 1}>
                    <div><img src={mas} className='img-paloma' /></div>
                  </Col>
                  <Col md={1} xs={ 1}>
                    <img src={service4} className='img-paloma'/>
                  </Col>
                  <Col md={10} xs={10}>
                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 12 }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Festivales</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Eventos de marca </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Convenciones</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Corporativo</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Seminarios </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Escenarios </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Conferencias </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Audio </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Video </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Pantallas led </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Alfombra roja y pasarelas </div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                    </Container>
                  </Col>
                </Row>       
              </div>
            }

            { serviceSelected == 'hoveringMobiliario' &&
              <div id="service_2" className={ "animate__animated animate__fadeInUp send-front-90 animate__fast" }>
                <Row>
                  <Col md={1} xs={ 1}>
                    <div><img src={mas} className='img-paloma' /></div>
                  </Col>
                  <Col md={1} xs={ 1}>
                    <img src={service5} className='img-paloma'/>
                  </Col>
                  <Col md={10} xs={10}>
                    <div className='home-subtitle-white'>Mobiliario<br /></div><br />

                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 12 }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Corporativo </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Hotelería </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Restaurantes </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Residencial</div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                    </Container>
                  </Col>
                </Row>       
              </div>
            }

            { serviceSelected == 'hoveringImpresion' &&
              <div id="service_2" className={ "animate__animated animate__fadeInUp send-front-90 animate__fast" }>
                <Row>
                  <Col md={1} xs={ 1}>
                    <div><img src={mas} className='img-paloma' /></div>
                  </Col>
                  <Col md={1} xs={ 1}>
                    <img src={service6} className='img-paloma'/>
                  </Col>
                  <Col md={10} xs={10}>
                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 12 }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Volumétricos </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Impresión 3D </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Anuncios luminosos  </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Logística</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Staff</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Mantenimiento</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Branding</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Re-branding</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Packaging</div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                    </Container>
                  </Col>
                </Row>       
              </div>
            }

            { serviceSelected == 'hoveringPublicidad' &&
              <div id="service_2" className={ "animate__animated animate__fadeInUp send-front-90 animate__fast" }>
                <Row>
                  <Col md={1} xs={ 1}>
                    <div><img src={mas} className='img-paloma' /></div>
                  </Col>
                  <Col md={1} xs={ 1}>
                    <img src={service7} className='img-paloma'/>
                  </Col>
                  <Col md={10} xs={10}>
                    <Container className='home-desc-section'>
                        <Row>
                          <Col md={ 12 }>
                            <Container>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Renta de pantallas </div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Venta de pantallas</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Contenido</div></Col>
                              </Row>
                              <Row>
                                  <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                  <Col className='padd-left'><div className='margin-service service-tab'>Instalación</div></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                    </Container>
                  </Col>
                </Row>       
              </div>
            }
               
          </Col>

          <Col md={ 5 } xs={5} className='service-container'>
            <div id="pop" className='home-services-title l-purple' onClick={() => handleOnClick(0)} ref={refExhibidores}>  <img src={mas} className='mas-service' />  POP</div>
            <div id="retail" className='home-services-title ' onClick={() => handleOnClick(1)} ref={refMarketing}>  <img src={mas} className='mas-service' />  Retail</div>
            <div id="stands" className='home-services-title ' onClick={() => handleOnClick(2)} ref={refMoldes}>  <img src={mas} className='mas-service' />  Stands publicitarios</div>
            <div id="eventos" className='home-services-title ' onClick={() => handleOnClick(3)} ref={refVolumen}>  <img src={mas} className='mas-service' />  Eventos y convenciones</div> 
            <div id="espacios" className='home-services-title ' onClick={() => handleOnClick(4)} ref={refMobiliario}>  <img src={mas} className='mas-service' />  Espacios fijos e interiorismo</div>
            <div id="proyectos" className='home-services-title ' onClick={() => handleOnClick(5)} ref={refImpresion}>  <img src={mas} className='mas-service' />  Proyectos especiales</div>
            <div id="tecno" className='home-services-title ' onClick={() => handleOnClick(6)} ref={refPublicidad}>  <img src={mas} className='mas-service' />  Tecnología</div>
          </Col>
        </Row>
    </Container>
    </div>
    )}
        </div>
        <div style={{ background: '#fff' }}>
          <Container className='section' style={{ background: '#fff' }}>
            <Row>
                <div className='home-title-black'> Marcas <span className="line"></span>
                </div>
            </Row>
            <br/><br/>
            <Row>
                <div>
                  <Marquee direction="left" gradient={ !isMobile }>
                    <div align="center"><img src={hut} className='marquee-img'/></div>
                    <div align="center"><img src={genox} className='marquee-img' /></div>
                    <div align="center"><img src={cristal} className='marquee-img' /></div>
                    <div align="center"><img src={gigante} className='marquee-img' /></div>
                    <div align="center"><img src={materias} className='marquee-img' /></div>
                    <div align="center"><img src={waldos} className='marquee-img' /></div>
                    <div align="center"><img src={ventura} className='marquee-img' /></div>
                    <div align="center"><img src={dyson} className='marquee-img'/></div>
                  </Marquee>
                </div>
                <div style={{ height: '50px' }}></div>
                <div>    
                  <Marquee direction="right" gradient={ !isMobile }>
                    <div align="center"><img src={boss} className='marquee-img' /></div>
                    <div align="center"><img src={hp} className='marquee-img' /></div>
                    <div align="center"><img src={recorcholis} className='marquee-img'/></div>
                    <div align="center"><img src={lumbre} className='marquee-img' /></div>
                    <div align="center"><img src={cuadra} className='marquee-img' /></div>
                    <div align="center"><img src={walmart} className='marquee-img' /></div>
                    <div align="center"><img src={monkey} className='marquee-img' /></div>
                    <div align="center"><img src={ramon} className='marquee-img' /></div>
                  </Marquee>
                </div>
            </Row>
            <br/><br/>
          </Container>
        </div>

        <div ref={refContact} className='footer section'>
        <div align="center">
          <div className='contact-container'>
          <Container>
            <Row>
              <Col>
                <div className='footer-text-title'> CONTÁCTANOS </div>
              </Col>
            </Row>
            <br/>
            <Row className='align-items-center'>
              <Col md={ 5 } xs={ 12 } className='footer-text-desc' align="center">
                <div>
                  <br/>
                  <div> <img src={ paloma } className='paloma-icon'/> </div> <br/>
                  <div className='footer-text-desc' ><a href='tel:5567020600' target='_blank' > <Whatsapp /> &nbsp;&nbsp;55 6702 0600 </a></div>
                  <div className='footer-text-desc'><a href='mailto:contacto@afirmativo.mx' target='_blank' > <Envelope /> &nbsp;&nbsp;contacto@afirmativo.mx </a></div>
                  <br/>
                  <Container>
                    <Row>
                      <Col md={ 2 } xs={ 0 } />
                      <Col md={ 2 } xs={ 3 }> <a href="https://www.instagram.com/afirmativo.agencia/" target='_blank'> <img src={ insta } className='rrss-icons' /> </a> </Col>
                      <Col md={ 2 } xs={ 3 }> <a href="https://www.facebook.com/profile.php?id=100092665577944" target='_blank'> <img src={ face } className='rrss-icons' /> </a> </Col>
                      <Col md={ 2 } xs={ 3 }> <a href="https://www.tiktok.com/@afirmativo.agencia" target='_blank'> <img src={ tik } className='rrss-icons' /> </a> </Col>
                      <Col md={ 2 } xs={ 3 }> <a href="https://www.linkedin.com/company/afirmativoagencia/about/" target='_blank'> <img src={ linked } className='rrss-icons' /> </a> </Col>
                      <Col md={ 2 } xs={ 0 } />
                    </Row>
                    <Row>
                      <Col>
                        <div className='footer-text-link'>@afirmativo.agencia</div>
                      </Col>
                    </Row>
                  </Container>
                  <br/>
                  <Container>
                    <Row className='align-items-center mb-2'>
                      <Col md={ 6 } xs={ 12 }> 
                        <div> <img src={ location } className='location-icon' /> </div>
                        <div className='foo-contact-title'>PLANTA</div>
                        <div className='foo-contact-info'>Lago de Guadalupe, Atizapán</div>
                      </Col>
                      <Col md={ 6 } xs={ 12 }> 
                        <div> <img src={ location } className='location-icon' /> </div>
                        <div className='foo-contact-title'>CORPORATIVO</div>
                        <div className='foo-contact-info'>Cd. Satélite, Naucalpan</div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
              <Col md={ 7 } xs={ 12 }>
                <div className='form-container'>
                  <Form id="contactForm" onSubmit={ handleOnSubmit }>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label></Form.Label>
                        <Form.Control type="text" className='form-footer' placeholder="Nombre:" name='name' value={ name } onChange={ () => setName()}/><br/>
                        <Form.Control type="email" className='form-footer' placeholder="Email:" name='email' value={ email } onChange={ () => setEmail()}/><br/>
                        <Form.Control type="number" className='form-footer' placeholder="Teléfono:" name='phone' value={ phone } onChange={ () => setPhone()}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Control as="textarea" className='form-footer' placeholder="Mensaje:" rows={3} name='comments' value={ comments } onChange={ () => setComments()}/>
                    </Form.Group>
                      <div align="right"><Button disabled={isLoading} className="button-footer" type="submit" >{isLoading ? 'Enviando…' : 'ENVIAR'}</Button></div>
                      <br />
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          </div>

          <Container>
            <br/>
            <Row className='line-footer' />
            <Row className='align-items-center' align="center">
              <Col md={2} onClick={ () => handleClick('refHome') } style={{ cursor: 'pointer' }}>
                { isMobile && <br/> }
                <div><img src={logo} width={90 }/></div>
                </Col>
              <Col md={8} className='footer-text-desc' style={{ cursor: 'pointer' }} onClick={ () => setShow(true) }>
                <div align="center" className='footer-text-privacy'>Aviso de privacidad</div>
                </Col>
              <Col md={2}> 
                <span className="text-mebs" style={{ verticalAlign: "center", fontSize: "10px" }}> by </span>
                <a href='https://grupomebs.com/' target='_blank'><span> <img src={ mebs } width={ 90 }/> </span></a>
              </Col>
            </Row>
            
          </Container>
        </div>

      </div>
    </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="footer-privacity">
          <br/>
          <p>GRUPO MEBS, S.A. DE C.V., (en lo sucesivo MEBS), con domicilio en Jos&eacute; Guadalupe Posada n&uacute;mero 16, colonia Ciudad Sat&eacute;lite, Naucalpan de Ju&aacute;rez, C.P. 53100, Estado de M&eacute;xico es el responsable del uso y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los datos personales que en su caso nos proporcione, los utilizaremos para las siguientes finalidades:&nbsp;</p>

            <p>&nbsp;</p>

            <ol>
              <li>Proveerle los servicios o productos que comercializamos.</li>
              <li>Brindarle los servicios que nos solicite.</li>
              <li>Conocer sus necesidades de productos o servicios para estar en posibilidad de ofrecerle los que m&aacute;s se adecuen a sus preferencias.</li>
              <li>Enviar por cualquier medio f&iacute;sico o electr&oacute;nico (correos electr&oacute;nicos, mensajes de texto, redes sociales, mensajer&iacute;a instant&aacute;nea, etc.), conocido o por conocerse, informaci&oacute;n de car&aacute;cter comercial, de mercadeo y promocional sobre los servicios o promociones desarrolladas por MEBS, sus empresas filiales, subsidirias y/o relacionadas, as&iacute; como de sus socios comerciales y/o proveedores.</li>
              <li>Identificaci&oacute;n y contacto.</li>
              <li>Fines estad&iacute;sticos.</li>
              <li>Elaboraci&oacute;n de bases de datos.</li>
              <li>Atender quejas y aclaraciones, y en su caso, tratarlos para fines compatibles con los mencionados en este Aviso de privacidad y que se consideren an&aacute;logos para efectos legales.</li>
            </ol>

            <p>&nbsp;</p>

            <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, podremos solicitarle los siguientes datos personales:</p>

            <ol>
              <li>Nombre.</li>
              <li>Tel&eacute;fono.</li>
              <li>Correo electr&oacute;nico.</li>
            </ol>

            <p>MEBS no recaba Datos Personales Sensibles. No obstante, si se diera el caso, se obtendr&aacute; el consentimiento expreso y por escrito del titular para su tratamiento, a trav&eacute;s de su firma aut&oacute;grafa, firma electr&oacute;nica o cualquier mecanismo de autenticaci&oacute;n.</p>

            <p>&nbsp;</p>

            <p>Le informamos que sus datos personales pueden ser compartidos dentro y fuera del pa&iacute;s para las finalidades antes mencionadas, &uacute;nicamente con empresas filiales, subsidirias y/o relacionadas con MEBS.</p>

            <p>&nbsp;</p>

            <p>Usted acepta y reconoce que las transferencias anteriormente descritas son necesarias para cumplir con las obligaciones que tiene pactadas MEBS con usted por lo que no se requiere su consentimiento para realizar las mismas.</p>

            <p>&nbsp;</p>

            <p>Asimismo, le informamos que para el desarrollo de algunos servicios, productos y/o promociones MEBS podr&aacute; requerir la participaci&oacute;n de socios comerciales y/o proveedores a los cuales se les podr&aacute; dar acceso a nuestras bases de datos para finalidades especificas, en el entendido que en todo momento MEBS conservar&aacute; la propiedad de dichas bases de datos.</p>

            <p>&nbsp;</p>

            <p>Usted tiene derecho a conocer que datos personales tenemos de usted, para que los utilizamos y las condiciones del uso que les damos (Acceso).&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que estar desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no este siendo utilizada adecuadamente (Cancelaci&oacute;n) as&iacute; como oponerse al uso de sus datos personales para fines especif&iacute;cos (Oposici&oacute;n). Estos derechos se conocen como Derechos ARCO.</p>

            <p>&nbsp;</p>

            <p>Para revocar su consentimiento para el tratamiento de sus datos personales y el ejercicio de sus Derechos ARCO, deber&aacute; presentar una solicitud (la &ldquo;Solicitud ARCO&rdquo;), la cual deber&aacute; enviar al correo electr&oacute;nico contacto@grupomebs.com, acompa&ntilde;ada de la siguiente informaci&oacute;n y documentaci&oacute;n:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su nombre, domicilio y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud ARCO.</li>
              <li>Los documentos que acrediten su identidad (copia de IFE, pasaporte o cualquier otra identificaci&oacute;n oficial) o en su caso,los documentos que acrediten su representaci&oacute;n legal.</li>
              <li>Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO.</li>
              <li>Cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales.</li>
              <li>En caso de solicitar una rectificaci&oacute;n de datos, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar ladocumentaci&oacute;n que sustente su petici&oacute;n, y la indicaci&oacute;n del lugar donde se podr&aacute;n revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</li>
            </ul>

            <p>&nbsp;</p>

            <p>MEBS responder&aacute; su Solicitud ARCO y expresar&aacute;&nbsp; los motivos de su decisi&oacute;n mediante un correo electr&oacute;nico en un plazo m&aacute;ximo de 20 d&iacute;as naturales contados desde el d&iacute;a en que se haya recibido su Solicitud ARCO.&nbsp;</p>

            <p>&nbsp;</p>

            <p>En caso de que la Solicitud ARCO se conteste de manera afirmativa o procedente, los cambios solicitados se har&aacute;n en un plazo m&aacute;ximo de 15 d&iacute;as naturales.&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los plazos antes referidos se podr&aacute;n prorrogar por una sola vez por un periodo igual en caso de ser necesario.</p>

            <p>&nbsp;</p>

            <p>MEBS podr&aacute; negar el acceso para que usted ejerza los Derechos ARCO en los siguientes supuestos:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Cuando Usted no sea el titular de los datos personales, o no pueda acreditar la representaci&oacute;n del titular.</li>
              <li>Cuando sus datos personales no obren en las bases de datos de MEBS.</li>
              <li>Cuando se lesionen los derechos de un tercero.</li>
              <li>Cuando exista un impedimento legal o la resoluci&oacute;n de una autoridad competente, que restrinja sus Derechos ARCO, y cuando la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n haya sido previamente realizada.</li>
            </ul>

            <p>&nbsp;</p>

            <p>La negativa podr&aacute; ser parcial, en cuyo caso MEBS efectuar&aacute; el acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n en la parte procedente.</p>

            <p>En el caso de revocar el consentimiento que&nbsp; nos haya otorgado para el tratamiento de sus datos personales es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando servicios.</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Con el objetivo de mejorar la experiencia de sus usuarios en sus sitios web, MEBS podr&aacute; utilizar &ldquo;cookies&rdquo; y/o &ldquo;web beacons&rdquo;. Las &quot;cookies&quot; son archivos de texto que son descargados autom&aacute;ticamente y almacenados en el disco duro del equipo de c&oacute;mputo del usuario al navegar en una p&aacute;gina de Internet para recordar algunos datos sobre este usuario, entre ellos, sus preferencias para la visualizaci&oacute;n de las p&aacute;ginas en ese servidor, nombre y contrase&ntilde;a. Por su parte, las &quot;web beacons&quot; son im&aacute;genes insertadas en un p&aacute;gina de Internet o correo electr&oacute;nico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario, duraci&oacute;n del tiempo de interacci&oacute;n en dicha p&aacute;gina y el tipo de navegador utilizado, entre otros. Le informamos que utilizamos &quot;cookies&quot; y &quot;web beacons&quot; con fines &uacute;nicamente de marketing, experiencia de usuario y medici&oacute;n, para obtener informaci&oacute;n como la siguiente:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su tipo de navegador y sistema operativo;</li>
              <li>Las p&aacute;ginas de Internet que visita;</li>
              <li>Los v&iacute;nculos que sigue;</li>
              <li>La direcci&oacute;n IP; y el sitio que visit&oacute; antes de entrar al nuestro.</li>
            </ul>

            <p>&nbsp;</p>

            <p>Estas cookies y otras tecnolog&iacute;as pueden ser deshabilitadas. Para conocer c&oacute;mo hacerlo, consulte el men&uacute; de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las &quot;cookies&quot;, es posible que no pueda acceder a ciertas funciones personalizadas en nuestro sitio web.</p>

            <p>&nbsp;</p>

            <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras praticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

            <p>&nbsp;</p>

            <p>El sitio de internet https://grupomebs.com/ puede contener enlaces a otros sitios web, respecto de los cuales MEBS no se hace responsable de las pr&aacute;cticas y usos de los otros sitios, incluyendo los de nuestros patrocinadores y/o socios comerciales</p>

            <p>&nbsp;</p>

            <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s de nuestro sitio web https://grupomebs.com/.</p>

            <p>&nbsp;</p>

            <p>El Responsable cuenta con medidas de seguridad f&iacute;sicas, administrativas y t&eacute;cnicas para resguardar sus Datos Personales. No obstante, en caso de que ocurra alguna vulneraci&oacute;n a la seguridad que afecte de forma significativa sus derechos patrimoniales o morales, el Responsable lo har&aacute; de su conocimiento mediante el correo electr&oacute;nico que proporcion&oacute;, a fin de que usted pueda tomar las medidas necesarias correspondientes para la defensa de los mismos.</p>

            <p>&nbsp;</p>

            <p>En el momento que usted se registra en nuestro portal o nos proporciona sus datos personales usted manifiesta su consentimiento expreso para el tratamiento de sus datos personales de conformidad con este Aviso de Privacidad.</p>

        </Modal.Body>
      </Modal>

    </>
  );
}

export default App;
